
.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

.HeaderBar {
  background-color: rgb(0, 0, 0);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.Main {
  color: rgb(255, 127, 22);

}

.myLegend {
  color: dodgerblue;
}

.verticalCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-46%);
  transform: translateY(-46%);
}

.teleprompterReadStyle {
font-size: 5rem;
color: white;
}

.teleprompterEditStyle {
font-size: 1rem;
color: black;
}
.citation-block {
  padding: 0rem;
  margin: 0rem;
}
.public-DraftStyleDefault-pre {
  padding: 0rem;
  margin: 0rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.16);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(2, 2, 2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(78, 78, 78);
}

@media only screen and (min-width: 300px) {

  .smText {
    font-size: 7pt;
  }

  .mdText {
    font-size: 10pt;
  }

  .lgText {
    font-size: 12pt;
  }

}

@media only screen and (min-width: 600px) {

  .smText {
    font-size: small;
  }

  .mdText {
    font-size: medium;
  }

  .lgText {
    font-size: large;
  }

}

@media only screen and (min-width: 900px) {

  .smText {
    font-size: 12pt;
  }

  .mdText {
    font-size: 16pt;
  }

  .lgText {
    font-size: 22pt;
  }

}

.carousel-demo .product-item .product-item-content {
  border: 1px solid var(--surface-d);
  border-radius: 3px;
  margin: .3rem;
  text-align: center;
  padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
  width: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}